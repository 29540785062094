<template>
  <div class="media-app-report-container">
    <homeTitle title="TOP应用" borderColor="#008c8c" />
    <div class="control">
      <a-range-picker
        class="search_item"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      />
      <router-link :to="{ path: '/reportdatas/mediareport' }">查看更多>></router-link>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        tableLayout="fixed"
        :loading="isLoading"
        bordered
      >
        <div slot="appName" slot-scope="appName, record">
          <AndroidSvg class="os-icon" v-if="record.os === 1" />
          <IosSvg class="os-icon" v-else />
          <span>{{ appName }}</span>
        </div>
        <div slot="requestFilledRate" slot-scope="requestFilledRate">
          <span>{{ requestFilledRate }}%</span>
        </div>
        <div slot="unitImpressionRate" slot-scope="unitImpressionRate">
          <span>{{ unitImpressionRate }}%</span>
        </div>
        <div slot="unitClickRate" slot-scope="unitClickRate">
          <span>{{ unitClickRate }}%</span>
        </div>
      </a-table>
      <m-empty style="min-height: 40vh" v-if="dataList.length === 0">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { allColumns } from './allColumns'
import { getAppData } from '@/api/mediaDashboard'
import { getMediaReportRuleVo } from '@/api/reportdatas'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import HomeTitle from '../homeTitle.vue'
import { getDuration } from '@/utils/duration'
import { numFormat } from '@/utils/dealNumber'

export default {
  mixins: [mixDate],
  components: { IosSvg, AndroidSvg, HomeTitle },
  data () {
    return {
      query: {
        dateEnd: '',
        dateStart: ''
      },
      dataList: [],
      columns: [],
      isLoading: false,
      allColumns
    }
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(30, 1)
    this.rangePickerDate = [start, end]
    this.query.dateEnd = endDate
    this.query.dateStart = startDate
    this.getReportData()
    this.getColumns()
  },
  methods: {
    async getColumns () {
      const resp = await getMediaReportRuleVo()
      const custTags = resp.data ? resp.data.data : []
      this.columns = [
        {
          title: '应用名称',
          dataIndex: 'appName',
          width: '100px',
          scopedSlots: {
            customRender: 'appName'
          }
        },
        ...this.allColumns.filter((item) => custTags.includes(item.dataIndex))
      ]
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.query = {
        ...this.query,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.getReportData()
    },
    async getReportData () {
      this.isLoading = true
      const resp = await getAppData(this.query)
      this.dataList = resp.data ? resp.data : []
      this.isLoading = false
      this.dataList.forEach((item) => {
        item.request = numFormat(item.request, 3, '')
        item.unitImpression = numFormat(item.unitImpression, 3, '')
        item.unitClick = numFormat(item.unitClick, 3, '')
        item.mediaUnitRevenue = numFormat(item.mediaUnitRevenue, 3, '')
        item.unitEcpm = numFormat(item.unitEcpm, 3, '')
        item.dau = numFormat(item.dau, 3, '')
        item.unitRevenue = numFormat(item.unitRevenue, 3, '')
        item.response = numFormat(item.response, 3, '')
        item.arpu = numFormat(item.arpu, 3, '')
      })
    }
  }
}
</script>
<style lang="less" scoped>
.media-app-report-container {
  padding: 10px 10px 30px 10px;
  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .table {
    padding: 10px;
    .os-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
}
</style>
