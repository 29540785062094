<template>
  <div class="media-dashboard-container">
    <div class="media-dashboard-top">
      <div class="media-dashboard-top-left">
        <div class="media-dashboard-top-left-top module">
          <MediaBoard />
        </div>
        <div class="media-dashboard-top-left-bottom module">
          <MainChart />
        </div>
      </div>
      <div class="media-dashboard-top-right">
        <div class="media-dashboard-top-right-top module">
          <MiniChart />
        </div>
        <div class="media-dashboard-top-right-bottom module">
          <SiderCharts />
        </div>
      </div>
    </div>
    <div class="media-dashboard-bottom module">
      <MediaAppReport />
    </div>
  </div>
</template>

<script>
import MediaBoard from './components/mediaboard'
import MainChart from './components/mainChart'
import MiniChart from './components/miniChart'
import SiderCharts from './components/siderCharts'
import MediaAppReport from './components/mediaAppReport'
export default {
  components: { MediaBoard, MainChart, MiniChart, SiderCharts, MediaAppReport }
}
</script>

<style lang="less" scoped>
.media-dashboard-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  overflow: flex;
  .module {
    border-radius: 10px;
    background: #fff;
  }
  .media-dashboard-top {
    margin-bottom: 10px;
    // aspect-ratio: 100/42;
    display: flex;
    .media-dashboard-top-left {
      flex-grow: 1;
      width: 0;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      .media-dashboard-top-left-top {
        margin-bottom: 10px;
        height: 200px;
      }
      .media-dashboard-top-left-bottom {
        height: 0;
        flex-grow: 1;
      }
    }
    .media-dashboard-top-right {
      // width: 30%;
      width: 430px;
      display: flex;
      flex-direction: column;
      .media-dashboard-top-right-top {
        margin-bottom: 10px;
        height: 200px;
      }
      .media-dashboard-top-right-bottom {
        height: 0;
        flex-grow: 1;
        min-height: 650px;
      }
    }
  }
  .media-dashboard-bottom {
  }
}
</style>
