<template>
  <div class="media-board-container">
    <homeTitle title="预估收益" borderColor="#008c8c" />
    <div class="board-list">
      <div class="board-item" v-for="(item, index) in dataList" :key="index">
        <div class="board-item-title">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="board-item-content">
          <span class="numerical-value">{{ numFormat(item.value) }}</span>
          <div class="numerical-ratio" v-if="item.hasRatio">
            <span class="ratio-text">环比</span>
            <span
              :class="{ ratio: true, increase: item.ratio > 0, decrease: item.ratio <= 0 }"
            >{{ item.ratio > 0 ? '+' : '' }}{{ item.ratio }}%</span
            >
            <div class="rdtio-dot" :style="{ borderColor: colorList[index] }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeTitle from './homeTitle'
import { numFormat } from '@/utils/dealNumber'
import { getRevenueData } from '@/api/mediaDashboard'
export default {
  components: { homeTitle },
  data () {
    return {
      dataList: [
        {
          value: 0,
          hasRatio: true,
          title: '昨日收益',
          ratio: 0
        },
        {
          value: 0,
          hasRatio: true,
          title: '近七日收益',
          ratio: 0
        },
        {
          value: 0,
          hasRatio: true,
          title: '本月累计收益',
          ratio: 0
        },
        {
          value: 0,
          hasRatio: false,
          title: '总收益',
          ratio: 0
        }
      ],
      colorList: ['#7ACDED', '#EF6666', '#FAC858', '#91CC75', '#5470C6']
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    numFormat,
    async getDataList () {
      const resp = await getRevenueData()
      this.dataList = []
      this.dataList.push({
        value: resp.data.yesterdayRevenue || 0,
        hasRatio: true,
        title: '昨日收益',
        ratio: resp.data.yesterdayRatio || 0
      })
      this.dataList.push({
        value: resp.data.sevenDaysRevenue || 0,
        hasRatio: true,
        title: '近七日收益',
        ratio: resp.data.sevenDaysRatio || 0
      })
      this.dataList.push({
        value: resp.data.monthRevenue || 0,
        hasRatio: true,
        title: '本月累计收益',
        ratio: resp.data.monthRatio || 0
      })
      // this.dataList.push({
      //   value: resp.data.lastMonthRevenue || 0,
      //   hasRatio: true,
      //   title: '上月累计收益',
      //   ratio: resp.data.lastMonthRatio || 0
      // })
      this.dataList.push({
        value: resp.data.sumRevenue || 0,
        title: '总收益',
        hasRatio: false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.media-board-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0;
  .board-list {
    overflow: hidden;
    flex-grow: 1;
    height: 0;
    margin: 5px 10px 15px 10px;
    display: flex;
    .board-item {
      user-select: none;
      border-radius: 10px;
      flex: 1;
      margin: 7px;
      background: #f3f4f9;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        transform: scale(1.03);
      }
      .board-item-title {
        padding: 10px 10px 0 10px;
        h3 {
          color: #b3b3b3;
          line-height: 25px;
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
      }
      .board-item-content {
        padding: 0 10px;
        position: relative;
        .numerical-value {
          color: #333333;
          font-size: 24px;
          line-height: 40px;
          font-weight: 700;
        }
        .numerical-ratio {
          font-size: 13px;
          line-height: 30px;
          .ratio-text {
            color: #b3b3b3;
            margin: 0 10px 0 0;
          }
          .ratio {
            &.increase {
              color: #f02388;
            }
            &.decrease {
              color: #27ca8a;
            }
          }
        }
        .rdtio-dot {
          height: 15px;
          width: 15px;
          border-radius: 50%;
          border-style: solid;
          border-width: 4px;
          position: absolute;
          top: 70%;
          right: 20px;
        }
      }
    }
  }
}
</style>
