<template>
  <div class="main-chart-container">
    <homeTitle title="数据趋势" borderColor="#ff6600" />
    <div class="main-chart-box">
      <chart />
    </div>
  </div>
</template>

<script>
import homeTitle from '../homeTitle.vue'
import chart from './chart'
export default {
  components: { homeTitle, chart }
}
</script>
<style lang="less" scoped>
.main-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .main-chart-box {
    margin: 0 10px 10px 10px;
    flex-grow: 1;
    height: 0;
  }
}
</style>
