<template>
  <div class="mini-chart-container">
    <homeTitle title="近14日eCPM趋势" borderColor="#3366fa" />
    <div class="mini-chart-box" ref="chart"></div>
  </div>
</template>

<script>
import homeTitle from './homeTitle.vue'
import { getEcpmTrend } from '@/api/mediaDashboard'
import * as echarts from 'echarts'
export default {
  components: { homeTitle },
  data () {
    return {
      myEcharts: null,
      options: {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        grid: {
          left: '5%',
          top: '2%',
          right: '3%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          data: [
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201',
            '20230201'
          ],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            name: 'eCPM',
            data: [150, 230, 224, 218, 135, 147, 260, 150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            lineStyle: {
              color: '#6541FF'
            },
            itemStyle: {
              normal: {
                color: '#6541FF',
                lineStyle: {
                  color: '#6541FF',
                  width: 1
                }
              }
            },
            smooth: false,
            areaStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#6541FF' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 0, 0, 0)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                }
              }
            }
          }
        ]
      }
    }
  },
  async mounted () {
    await this.getChartData()
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    this.initChart()
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    async getChartData () {
      const resp = await getEcpmTrend()
      this.options.series[0].data = resp.data ? resp.data.list[0].data : []
      this.options.xAxis.data = resp.data ? resp.data.lable : []
    },
    initChart () {
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>
<style lang="less" scoped>
.mini-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .mini-chart-box {
    margin: 0 0 10px 0;
    flex-grow: 1;
    height: 0;
  }
}
</style>
